
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantinwkJsMmM6Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as _91txId_93yb7MakXBQLMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue?macro=true";
import { default as affiliateqeatB8FQ8hMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilWfChqXOeSfMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantH8bcJ3FKwHMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankoRvpU4AlYahMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserFA5PKGps37Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingosMqclLH09LMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogTajST0QQ7sMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodeCQjvldrvWaMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinoEgQpK8Lo4eMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitik4FNSHyxwvlMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as faqYHJxPIqA6SMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/faq.vue?macro=true";
import { default as _91slug_93HuzXspfpWsMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as index0wYsq3Hny3Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexakGUrMIT6aMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as ansvarligt_45spilSA3Er3ylP0Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue?macro=true";
import { default as beskederNA1hGNeoKmMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as biometrieBMbkuXhLNMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/biometri.vue?macro=true";
import { default as _91reportId_932GT3KtLo0rMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eid7ImVrm7cffMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as indbetalingsloftVcoYLF1hFrMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue?macro=true";
import { default as nedkoelingAOPAO0czBHMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue?macro=true";
import { default as ret_45profilhR5AJwm7QfMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/ret-profil.vue?macro=true";
import { default as sletningPhZttoMPaLMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/sletning.vue?macro=true";
import { default as spaeringllGNLzmGITMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/spaering.vue?macro=true";
import { default as kundeservicee3LU9Ak7wrMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45appmxrnbsR7S3Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45oskkUxCUXvE8Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as logindIVGziXwO8Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as presseIHR1oZj8Y3Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitik0CMLboLhptMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupEzd5pTFbEQMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilKo9lsoCsWUMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexpPan2FDi21Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtLEsc56LOm5Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93VelYDDIkWDMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvmOpGFSwY1UMeta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vindere5qRhtcLKB6Meta } from "/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantinwkJsMmM6Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "payments-pending",
    path: "/payments/pending/:txId",
    meta: _91txId_93yb7MakXBQLMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending/[txId].vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliateqeatB8FQ8hMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilWfChqXOeSfMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantH8bcJ3FKwHMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankoRvpU4AlYahMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserFA5PKGps37Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingosMqclLH09LMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogTajST0QQ7sMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodeCQjvldrvWaMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinoEgQpK8Lo4eMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitik4FNSHyxwvlMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqYHJxPIqA6SMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/faq.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93HuzXspfpWsMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: index0wYsq3Hny3Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexakGUrMIT6aMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-ansvarligt-spil",
    path: "/konto/ansvarligt-spil",
    meta: ansvarligt_45spilSA3Er3ylP0Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/ansvarligt-spil.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskederNA1hGNeoKmMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-biometri",
    path: "/konto/biometri",
    meta: biometrieBMbkuXhLNMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/biometri.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_932GT3KtLo0rMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eid7ImVrm7cffMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "konto-indbetalingsloft",
    path: "/konto/indbetalingsloft",
    meta: indbetalingsloftVcoYLF1hFrMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/indbetalingsloft.vue")
  },
  {
    name: "konto-nedkoeling",
    path: "/konto/nedkoeling",
    meta: nedkoelingAOPAO0czBHMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/nedkoeling.vue")
  },
  {
    name: "konto-ret-profil",
    path: "/konto/ret-profil",
    meta: ret_45profilhR5AJwm7QfMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/ret-profil.vue")
  },
  {
    name: "konto-sletning",
    path: "/konto/sletning",
    meta: sletningPhZttoMPaLMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/sletning.vue")
  },
  {
    name: "konto-spaering",
    path: "/konto/spaering",
    meta: spaeringllGNLzmGITMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/konto/spaering.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeservicee3LU9Ak7wrMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45appmxrnbsR7S3Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45oskkUxCUXvE8Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: logindIVGziXwO8Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseIHR1oZj8Y3Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitik0CMLboLhptMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupEzd5pTFbEQMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    meta: gemte_45spilKo9lsoCsWUMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexpPan2FDi21Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtLEsc56LOm5Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93VelYDDIkWDMeta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vindere5qRhtcLKB6Meta || {},
    component: () => import("/data/gitlab-runner/builds/Xk5sV_dG/0/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]